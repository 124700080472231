@import "../assets/icons/all.min.css";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
body,
#root,
html {
  font-family: "Charlie Display" !important;
  height: 100%;
  background-color: #f1f2f5;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
main::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* REACT DATES CALENDAR FIX */
.DateInput_input__small {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.SingleDatePicker_picker {
  z-index: 10;
}
.DateInput__small {
  width: 100% !important;
}
.DateInput__small > input {
  text-align: center;
  height: 30px !important;
  border-radius: 5px !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #e36a53 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #e36a53 !important;
  border: 1px double #e36a53 !important;
  color: #fff;
  font-weight: bold !important;
}
.SingleDatePickerInput__withBorder {
  border-radius: 5px !important;
  border: 1px solid #dbdbdb;
}
.DateInput {
  border-radius: 5px !important;
}
/* JSON DEBUG FIX */
.__json-pretty__ {
  padding: 7px;
  border-radius: 5px;
}
/* VIRTUAL TABLE FIX */
.v-l {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.d-none {
  display: none;
}
.rce-mbox {
  padding: 6px 9px 22px 9px;
}
.rce-mbox-time {
  bottom: -9px !important;
}
.rce-container-citem {
  margin-bottom: 15px;
}
.rce-mbox.rce-mbox-right {
  overflow: hidden;
}
.rce-mbox-photo--img {
  cursor: pointer;
}
.rce-citem-avatar img {
  width: auto !important;
  height: auto !important;
}
.rce-container-citem {
  width: 350px;
}
.rce-citem {
  cursor: default !important;
}
.fc-event {
  margin-bottom: 3px;
  padding-left: 5px;
}
.scheduler_default_corner div {
  display: none;
}
.scheduler_default_timeheadercol_inner {
  border: none !important;
}
.scheduler_default_main {
  border: none !important;
}
.scheduler_default_timeheadergroup {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #894386 !important;
  background: #f8f8f9 !important;
}
.scheduler_default_corner {
  background: #f8f8f9 !important;
}
.scheduler_default_timeheadercol {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #59596a !important;
  background: #f8f8f9 !important;
}
.scheduler_default_divider_horizontal {
  background-color: transparent !important;
}
.scheduler_default_rowheader {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #59596a;
  background-color: #ffffff !important;
}
.scheduler_default_cell.scheduler_default_cell_business {
  background-color: #ffffff !important;
}
.scheduler_default_cell {
  background-color: #f9f9fe !important;
}
.scheduler_default_rowheader_inner {
  position: absolute;
  border-right: 1px solid #f9f9fe !important;
  padding: 7px;
  display: flex;
  align-items: center;
}
.scheduler_default_timeheadergroup_inner {
  border-right: none !important;
  border-bottom: 1px solid #d7e1f0b0 !important;
}
.scheduler_default_resourcedivider {
  background: #d7e1f0b0 !important;
}
.scheduler_default_divider,
.scheduler_default_splitter {
  background: #d7e1f0b0 !important;
}
.scheduler_default_matrix_horizontal_line {
  background: #d7e1f0b0 !important;
}
.scheduler_default_scrollable::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.scheduler_default_scrollable::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}
.scheduler_default_scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #bebebe;
}
.scheduler_default_scrollable::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scheduler_default_event {
  font-family: Charlie Display;
  font-style: normal;
  font-weight: 900;
  font-size: 14px !important;
  line-height: 16px;
  color: #465ac2 !important;
}
.scheduler_default_event_inner {
  position: absolute;
  padding: 12px !important;
  border: none !important;
}
.scheduler_default_event_bar {
  display: none;
}
.scheduler_default_event_inner {
  background: #e3eeff !important;
  border-radius: 8px !important;
  margin: 3px;
}
.rce-container-citem {
  margin: 5px 10px;
}
ul {
  list-style-type: none !important;
}
#formRow {
  margin-bottom: 0 !important;
}
.ant-descriptions .ant-descriptions-item-content {
  background: white;
  border-radius: 5px;
  padding: 2px 5px;
}
.ant-descriptions-item-label {
  background-color: rgba(236, 236, 236, 0) !important;
  width: 70px;
}
.even-row {
  background-color: white !;
}
.bottle-uploader.bottle-uploader .ant-upload {
  width: 110px !important;
  height: 400px !important;
}
.ticket-uploader.ticket-uploader .ant-upload {
  width: 300px !important;
  height: 390px !important;
}
.ant-input-number-disabled {
  color: #000000 !important;
}
#formRow {
  margin-bottom: 0 !important;
}
.myCustomMapButton {
  font-size: 16px;
  margin-left: 8px;
  margin-top: 8px;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 3px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  cursor: pointer;
}
.myCustomMapButton:hover {
  color: dodgerblue;
}
.myCustomMapText {
  margin-top: 3px;
  font-weight: 700;
  position: absolute;
  top: 0px;
  left: 400px;
  font-size: 16px;
  margin-left: 8px;
  margin-top: 8px;
  border-radius: 3px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  cursor: pointer;
}
.myCustomMapTextDisplay {
  background-color: #fff;
  border: #fff;
}
.myCustomMapText:hover {
  color: dodgerblue;
}
.myCustomMapButtonSelected {
  color: dodgerblue;
  border-color: dodgerblue;
}
.myCustomMapButtonSelected:hover {
  color: #9e9e9e;
}
.myCustomMapButtondisabled {
  color: #434547;
  cursor: auto;
  background-color: #a5a5a5;
  border-color: #a5a5a5;
}
.myCustomMapButtondisabled:hover {
  color: #434547;
}
button[title="Dejar de dibujar"] {
  display: none !important;
}
.ant-menu {
  background-color: #222 !important;
}
.scheduler-container {
  display: flex;
  padding: 30px;
  width: 100%;
}
@media (max-width: 768px) {
  .scheduler-container {
    flex-direction: column;
  }
}
.controls-container {
  margin: 15px;
}
@media (max-width: 768px) {
  .controls-container {
    flex-direction: column;
  }
}
.select-control {
  width: 200px;
}
.date-control {
  width: 100px;
}
@media (max-width: 768px) {
  .select-control,
  .date-control {
    width: 100%;
    margin-bottom: 10px;
  }
}
.custom-radio-group {
  display: flex;
  flex-wrap: nowrap;
  /* Asegura que los botones no se ajusten a múltiples líneas */
}
.custom-radio-group .ant-radio-button-wrapper {
  height: auto;
  /* Ajusta la altura de los botones automáticamente */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: normal;
  /* Permite que el texto se ajuste a múltiples líneas */
  word-wrap: break-word;
  /* Asegura que las palabras largas se ajusten correctamente */
  padding: 8px 16px;
  /* Ajusta el espaciado interno según sea necesario */
}
